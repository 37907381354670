<template>
  <div v-if="data.single.length > 0">
    <div class="Test-paper-title">{{getTranslateIndex}}、单选题</div>
      <div class="questiontype-content"  v-for="(item,index) in data.single" :key="index">
        <div class="questiontype-top">
          <div class="Title"><span class="Title-span">【题文】{{index+1}}、</span><span v-html="item.title" class="html-span"></span></div>
        </div>
        <ul class="Options-list">
          <li v-for="(optionItem,index2) in item.options" :key="index2">
            <span>{{index2===0?'A':index2===1?'B':index2===2?'C':'D'}}、{{optionItem.name}}</span>
          </li>
        </ul>
        <div class="answer-analysis-foot">
          <div class="answer-analysis-foot-left">
            <span>难度:{{item.difficulty_id === 1?'容易':item.difficulty_id === 2?'较易':item.difficulty_id === 3?'普通':item.difficulty_id === 4?'较难':'困难'}}</span>
            <span>题型:单选题</span>
            <span>组卷次数:{{item.use_num}}次</span>
          </div>
          <div class="answer-analysis-foot-right">
            <div class="dele">
              <i @click="deleteOne(item)" class="iconfont">&#xe617;</i>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import functions from '@/utils/paperFunction'
export default {
  name: "SetPaperStepTwoSingle",
  props:['data', 'isShow'],
  data(){
    return {
      dataSingle:[]
    }
  },
  computed: {
    getTranslateIndex() {
      return functions.translateNumberByObject(this.data, 'single');
    }
  },
  methods:{
    deleteOne(item) {
      this.$emit('deleteItem', item);
    },
  }
}
</script>

<style scoped lang="scss">
ul,li{
  list-style: none;
  margin: 0;
  padding: 0;
}
::v-deep .html-span{
  p{
    padding: 0;
    margin: 0;
  }
}
.Test-paper-title{
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0 10px;
}
.questiontype-content{
  border: 1px solid #D2D2D2;
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
  .questiontype-top{
    display: flex;
    display: -webkit-flex;
    font-size: 14px;
    color: #333;
    font-weight: 500;
    /*padding: 4px 4px;*/
    .Title{
      display: -webkit-box;
      display: flex;
      font-size: 14px;
      color: #333;
      font-weight: 500;
      padding: 26px 0 0 30px;
      /*padding: 4px 4px;*/
      /*.Title-span{*/
      /*    min-width:36px;*/
      /*    display: inline-block;*/
      /*}*/
    }
  }
  .Options-list{
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding: 16px 0 0 30px;
    /*padding: 10px;*/
    li{
      width: 100%;
      line-height: 48px;
    }
  }
  .answer-analysis{
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    margin-bottom: 16px;
    .answer-analysis-top{
      font-size: 14px;
      margin:10px 0 0 30px;
      color: #666666;
      .answer-analysis-text{
        display: inline-block;
        color: #1AB163;
      }
    }
    .answer-analysis-label{
      //color: #409eff;
    }
    .answer-analysis-bottom{
      color: #666666;
      font-size: 14px;
      margin:10px 0 0 30px;
      .answer-analysis-text{
        display: inline-block;
      }
    }
  }
  .answer-analysis-foot{
    display: flex;
    display: -webkit-flex;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    background: #E7F6EF;
    height: 50px;
    line-height: 50px;
    padding: 0 22px 0 30px;
    margin-top: 3px;
    font-size: 14px;
    font-weight: 400;
    .answer-analysis-foot-left{
      display: flex;
      display: -webkit-flex;
      flex: 1;
      width: 1%;
      span{
        display: inline-block;
        margin-right: 50px;
        color: #666666;
        font-size: 14px;
      }
    }
    .answer-analysis-foot-right{
      i{
        font-size: 20px;
        color: #FF2000;
        cursor: pointer;
      }
    }
    .add-analysisBtn{
      padding:10px;
      i{
        font-size: 14px;
        color: #FFFFFF;
      }
      ::v-deep span{
        margin-left: 0;
      }
    }
  }
}
.null-data{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:14px;
  height: 558px;
}
::v-deep p {
  display: inline-block;
}
</style>
