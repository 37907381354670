<template>
  <div v-if="data.multiple.length > 0">
    <div class="Test-paper-title">{{getTranslateIndex}}、多选题</div>
    <div class="questiontype-content"  v-for="(item,index) in data.multiple" :key="index">
      <div class="questiontype-top">
        <div class="Title"><span class="Title-span">【题文】{{index+1}}、</span><span v-html="item.title" class="html-span"></span></div>
      </div>
      <ul class="Options-list">
        <li v-for="(optionItem,index2) in item.options" :key="index2">
          <span>{{index2===0?'A':index2===1?'B':index2===2?'C': index2 === 3 ?'D' : index2 === 4 ? 'E' : 'F'}}、{{optionItem.name}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import functions from "@/utils/paperFunction";
export default {
  name: "PreviewMultiplechoiceModule",
  props:['data'],
  computed: {
    getTranslateIndex() {
      return functions.translateNumberByObject(this.data, 'multiple');
    }
  },
}
</script>

<style scoped lang="scss">
ul,li{
  list-style: none;
  margin: 0;
  padding: 0;
}
::v-deep .html-span{
  p{
    padding: 0;
    margin: 0;
  }
}
.Test-paper-title{
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0 10px;
}
.questiontype-content{
  border: 1px solid #D2D2D2;
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
  .questiontype-top{
    display: flex;
    display: -webkit-flex;
    font-size: 14px;
    color: #333;
    font-weight: 500;
    /*padding: 4px 4px;*/
    .Title{
      display: -webkit-box;
      display: flex;
      font-size: 14px;
      color: #333;
      font-weight: 500;
      padding: 26px 0 0 30px;
      /*padding: 4px 4px;*/
      /*.Title-span{*/
      /*    min-width:36px;*/
      /*    display: inline-block;*/
      /*}*/
    }
  }
  .Options-list{
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding: 16px 0 0 30px;
    /*padding: 10px;*/
    li{
      width: 100%;
      line-height: 48px;
    }
  }
  .answer-analysis{
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    margin-bottom: 16px;
    .answer-analysis-top{
      font-size: 14px;
      margin:10px 0 0 30px;
      color: #666666;
      .answer-analysis-text{
        display: inline-block;
        color: #1AB163;
      }
    }
    .answer-analysis-label{
      //color: #409eff;
    }
    .answer-analysis-bottom{
      color: #666666;
      font-size: 14px;
      margin:10px 0 0 30px;
      .answer-analysis-text{
        display: inline-block;
      }
    }
  }
}
.null-data{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:14px;
  height: 558px;
}
::v-deep p {
  display: inline-block;
}
</style>