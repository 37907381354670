import { render, staticRenderFns } from "./PreviewQuestionsAnswersModule.vue?vue&type=template&id=4cbb10de&scoped=true&"
import script from "./PreviewQuestionsAnswersModule.vue?vue&type=script&lang=js&"
export * from "./PreviewQuestionsAnswersModule.vue?vue&type=script&lang=js&"
import style0 from "./PreviewQuestionsAnswersModule.vue?vue&type=style&index=0&id=4cbb10de&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cbb10de",
  null
  
)

export default component.exports